import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const NavigationLink = ({ label, to }) => {
  return (
    <div className="px-4 py-15">
      <Link
        className="font-heading font-medium text-xl text-dark-blue uppercase inline-flex items-center justify-center w-full space-x-2"
        to={to}
      >
        <span className="pt-1">{label}</span>
        <svg
          className="fill-current h-3"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23.69 15.81"
        >
          <path d="M15.85 0l-2.12 2.12 4.22 4.22H0v3h17.95l-4.35 4.35 2.12 2.12 7.97-7.97L15.85 0z" />
        </svg>
      </Link>
    </div>
  )
}

NavigationLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
}

NavigationLink.defaultProps = {}

export default NavigationLink
