import * as React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavigationLink from "../components/navigation-link"
import FlexibleContentWrapper from "../components/flexible-content-wrapper"
import Hero from "../components/hero"

const CaseStudy = ({ pageContext }) => {
  const { title, hero, flexibleContent } = pageContext

  return (
    <Layout>
      <Seo title={title} />

      <Hero
        title={title}
        image={hero.image}
        textColour={hero.textColour}
        backgroundColour={hero.backgroundColour}
        hideTitle={hero.hideTitle}
      />

      <FlexibleContentWrapper flexibleContent={flexibleContent} />

      <NavigationLink label="Katso kaikki työt" to="/tyot" />
    </Layout>
  )
}

CaseStudy.propTypes = {
  title: PropTypes.string,
  hero: PropTypes.string,
  flexibleContent: PropTypes.shape(),
}

CaseStudy.defaultProps = {}

export default CaseStudy
